import React, { useState } from "react";
import { Col, Row, Avatar, Tooltip } from "antd";

import {
  PlusOutlined,
  AppstoreOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

const GlobalSearchSpace = ({ spaceData, setIsSearchResult, setInputText }) => {
  const [errorImage, setErrorImage] = useState(false);
  // console.log(spaceData, "spaceData");
  const navigate = useNavigate();
  const routeHandle = () => {
    setIsSearchResult(false);
    setInputText("");
  };

  return (
    <div className="bg-white">
      <Row className="mb-3 " gutter={[24, 24]}>
        <Col
          xs={24}
          md={24}
          className="fs-20 text-bolder mb-3"
          data-testid="product-title"
        >
          <span className="mr-2">
            <RiseOutlined />
          </span>
          <span className="">Space</span>
        </Col>
        {spaceData?.data?.map((space) => {
          return (
            <Col xs={24} md={4} lg={4}>
              <div
                className="p-3 rounded shadow-lg bg-white"
                onClick={() => {
                  navigate(`space/${space?.id}`, {
                    state: { group: space },
                  });
                  routeHandle();
                }}
              >
                <div className="picture d-flex align-items-center justify-content-center">
                  {space?.logo && !errorImage ? (
                    <Avatar
                      size={60}
                      src={`${process.env.REACT_APP_IMG}/${space?.logo}`}
                      alt="groupcard"
                      onError={() => setErrorImage(true)}
                    />
                  ) : (
                    <Avatar
                      size={60}
                      style={{
                        backgroundColor: "#fff4cb",
                        color: "#ffd333",
                      }}
                      className="img-fluid text-capitalize"
                      alt="groupcard"
                    >
                      <span className="fs-32">{space?.title[0]}</span>
                    </Avatar>
                  )}
                </div>
                <div className="text-center mt-3 text-capitalize">
                  <Tooltip
                    className="text-capitalize"
                    placement="bottom"
                    title={space?.title}
                  >
                    <span className="group-title cursor-pointer">
                      {space?.title?.length >= 15
                        ? space?.title?.slice(0, 13) + "..."
                        : space?.title}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default GlobalSearchSpace;
