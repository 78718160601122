import React, { Suspense, useState, useEffect } from "react";
import "./LayoutBox.scss";

import { Layout, notification } from "antd";
import HeaderNav from "../../components/HeaderNav";
import SideBar from "../../components/SideBar";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SkeletonBoard from "../../components/SkeletonBoard";
import { EmployeeApi } from "../../api/Employee";
import { setUserData } from "../../reducers/User";
import { GlobalApi } from "../../api/Global";
import GlobalSearchContainer from "../../components/GlobalSearchContainer";

const { Content } = Layout;
const Cookies = require("js-cookie");

const LayoutBox = () => {
  // const [collapsed, setCollapsed] = useState(false);
  // const {
  //   token: { colorBgContainer, colorTextBase },
  // } = theme.useToken();
  const [inputText, setInputText] = useState();
  const [groupData, setGroupData] = useState();
  const [spaceData, setSpaceData] = useState();
  const [links, setLinks] = useState();
  // console.log(groupData, spaceData, links);
  const [isSearchResult, setIsSearchResult] = useState(true);
  const [isSearchResultFound, setIsSearchResultFound] = useState(false);
  // console.log(inputText, "inpur");
  const dispatch = useDispatch();
  const { isCollapse } = useSelector((state) => state.sidebarCollapse);
  const { userData } = useSelector((state) => state.loggedInUser);

  const authToken = Cookies.get("authToken");
  const isLogin = Cookies.get("isLogin");
  const empId = Cookies.get("emp_id");

  const handleSuccessGetEmpById = (response) => {
    if (response?.status === 200) {
      dispatch(setUserData(response?.data));
    }
  };

  const handleErrorGetEmpById = (error) => {
    notification.error({
      message: error?.message || "Somthing went wrong! Please refresh the page",
      duration: 3,
    });
  };

  useEffect(() => {
    if (empId) {
      EmployeeApi.getEmployeeById(
        handleSuccessGetEmpById,
        handleErrorGetEmpById
      );
    }
  }, [empId]);
  const handleSuccessGlobal = (response) => {
    // console.log(response, "re");
    if (response?.status === 200) {
      setIsSearchResult(false);
      setGroupData(response?.data?.group);
      setSpaceData(response?.data?.space);
      setLinks(response?.data?.link);
      setIsSearchResultFound(true);
    }
  };
  const handleErrorGlobal = (error) => {
    // notification.error({
    //   message: error?.message || "Somthing went wrong! Please refresh the page",
    //   duration: 3,
    // });
    setIsSearchResultFound(false);
    setIsSearchResult(false);
  };

  const onChangeSearch = (e) => {
    setInputText(e.target.value);
  };
  useEffect(() => {
    setIsSearchResult(true);
    if (inputText?.length > 0) {
      GlobalApi.getAllSearch(inputText, handleSuccessGlobal, handleErrorGlobal);
    }
  }, [inputText]);

  const handleClear = () => {
    setGroupData();
    setSpaceData();
    setLinks();
  };

  return (
    <>
      {authToken && isLogin === "1" ? (
        <div
          className={
            isCollapse
              ? "mainLayout w-100 h-100"
              : "mainLayout sideBarWithCollapse w-100 h-100"
          }
          data-testid="main-layout-id"
        >
          <Layout className="h-full">
            <SideBar
              setIsSearchResult={setIsSearchResult}
              setInputText={setInputText}
              handleClear={handleClear}
            />
            <Layout className="site-layout">
              <HeaderNav
                onChangeSearch={onChangeSearch}
                inputText={inputText}
              />
              {isSearchResult ? (
                <Content
                  style={{
                    padding: 36,
                    minHeight: 280,
                  }}
                >
                  <Suspense
                    fallback={
                      <SkeletonBoard className="p-3 shadow-md rounded bg-white h-full d-flex align-items-center justify-content-center" />
                    }
                  >
                    <Outlet />
                  </Suspense>
                </Content>
              ) : (
                <GlobalSearchContainer
                  groupData={groupData}
                  spaceData={spaceData}
                  links={links}
                  setIsSearchResult={setIsSearchResult}
                  setInputText={setInputText}
                  isSearchResultFound={isSearchResultFound}
                />
              )}
            </Layout>
          </Layout>
        </div>
      ) : (
        <Navigate to="/signin" />
      )}
    </>
  );
};

export default LayoutBox;
