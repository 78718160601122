import axios from 'axios';
import handleApiCall, { AuthHeader, AuthHeaderWithFormData } from '../handleApi'

const HOST = process.env.REACT_APP_HOST;

const Cookies = require('js-cookie');
const empId = Cookies.get('emp_id');

function generateQueryString(obj) {
    let queryString = "";
    
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        if(key !== 'total'){
            queryString += key + "=" + encodeURIComponent(obj[key]) + "&";
        }
      }
    }
    
    queryString = queryString.slice(0, -1); // Remove the trailing '&'
    
    return queryString;
  }


export const EmployeeApi = {
    getRole: (
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/roles`, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getDepartment: (
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/departments`, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getEmployee: (
        pagination,
        onSuccess,
        onError,
    ) => {

        // console.log('my query', generateQueryString(pagination));

        let myQuery = generateQueryString(pagination)

        return handleApiCall(
            axios.get(`${HOST}/users?${myQuery}`, AuthHeader),
            onSuccess,
            onError,
        );

        // return handleApiCall(
        //     axios.get(
        //         pagination?.search && pagination?.status ?
        //             `${HOST}/users?search=${pagination?.search}&status=${pagination?.status}&page=${pagination?.current}&limit=${pagination?.pageSize}` 
        //         : pagination?.search ?
        //             `${HOST}/users?search=${pagination?.search}&page=${pagination?.current}&limit=${pagination?.pageSize}` 
        //         : pagination?.status ? 
        //             `${HOST}/users?status=${pagination?.status}&page=${pagination?.current}&limit=${pagination?.pageSize}` 
        //         :`${HOST}/users?page=${pagination?.current}&limit=${pagination?.pageSize}`, AuthHeader),
        //     onSuccess,
        //     onError,
        // );
    },
    addEmployee: (
        data,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.post(`${HOST}/users`, data, AuthHeaderWithFormData),
            onSuccess,
            onError,
        );
    },
    updateEmployee: (
        id,
        formData,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.put(`${HOST}/users/${id}`, formData, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getEmployeeById: (
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/users/detail`, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getEmployeeDetailById: (
        data,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/users/detail/${data?.id}`, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getEmployeeNotInGroup: (
        data,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/users/users-not-in-group/${data.group_id}`, AuthHeader),
            onSuccess,
            onError,
        );
    },
    getDashboardStatistics: (
        data,
        onSuccess,
        onError,
    ) => {
        return handleApiCall(
            axios.get(`${HOST}/users/dashboard/${data.id}`, AuthHeader),
            onSuccess,
            onError,
        );
    },
}