import { notification } from 'antd';

const Cookies = require('js-cookie');
const authToken = Cookies.get('authToken');

// console.log('api root : authToken', authToken)

export const AuthHeader = {
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
};

export const AuthHeaderWithFormData = {
  headers: {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "multipart/form-data"
  },
};


export default function handleApiCall(
  result,
  onSuccess,
  onError,
) {
  result
    .then((response) => {
      // console.log('api root : response', response)
      if (response?.status === 200 || response?.status === 201) {
        if (onSuccess) {
          onSuccess(response?.data);
        }
      } else {
        if (onError) {
          onError(response?.data);
        }
        window.console.error(response);
      }
    })
    .catch((error) => {
      // console.log('api root : error', error)
      window.console.error(error);
      if (authToken && error?.response?.status === 401) {

        Cookies.remove('authToken');
        Cookies.remove('isLogin');
        Cookies.remove('role_id');
        Cookies.remove('emp_id');

        if (
          error?.response?.data?.status === 404 &&
          (window.location?.pathname === '/signin')
        ) {
          notification.error({
            message: error?.response?.data?.message,
            duration: 3,
          });
        }
        // window.location.replace('/signin')
      } else {
        if (onError) {
          onError(error?.response?.data);
        }
      }
    });
}
