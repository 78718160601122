import React, { useState } from "react";
import "./HeaderNav.scss";

import { Avatar, Col, Dropdown, Layout, notification, Row, Input } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AuthUserApi } from "../../api/Auth";
import { useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";

const Cookies = require("js-cookie");

const { Header } = Layout;
const { Search } = Input;

const HeaderNav = ({ onChangeSearch, inputText }) => {
  const { userData } = useSelector((state) => state.loggedInUser);
  const navigate = useNavigate();

  const [errorImage, setErrorImage] = useState(false);

  const items = [
    {
      label: "Profile",
      key: "1",
      icon: (
        <UserOutlined
          style={{ fontSize: "14px" }}
          data-testid="profile-test-id"
        />
      ),
      className: "header-profile-dropdown",
    },
    {
      label: "Logout",
      key: "2",
      icon: (
        <LogoutOutlined
          style={{ fontSize: "14px" }}
          data-testid="logout-test-id"
        />
      ),
      style: { color: "#ee4b4f" },
      className: "headerLogoutDropdown",
    },
  ];

  const handleSuccess = (response) => {
    if (response?.status === 200 || response?.status === 201) {
      Cookies.remove("authToken");
      Cookies.remove("isLogin");
      Cookies.remove("role_id");
      Cookies.remove("emp_id");

      navigate("/signin");
      notification.success({
        message: response?.message,
        duration: 3,
      });
    }
  };

  const handleError = (error) => {
    notification.error({
      message: error?.message || "Somthing went wrong! Please refresh the page",
      duration: 3,
    });
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      navigate("/profile");
    }
    if (key === "2") {
      AuthUserApi.logout(handleSuccess, handleError);
    }
  };

  return (
    <Header className="p-0 main-header-cont-box">
      <Row data-testid="main-header-id" className="pl-md-3 pr-md-3 ml-3 mr-2">
        <Col xs={19} sm={12} md={16} className="col">
          <SearchOutlined className="search-outlined" />
          <DebounceInput
            element={Input}
            onChange={onChangeSearch}
            minLength={2}
            debounceTimeout={500}
            placeholder="Search Group,Space or Link"
            className="w-md-50 p-2 search-input"
            value={inputText}
          />
        </Col>
        <Col xs={5} sm={12} md={8} className="pr-2">
          <div className="header-right-main d-flex justify-end ">
            <div className="header-right-profile  cursor-pointer">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  data-testid="profileDropdown"
                >
                  <span className="pr-md-2 welcome-msg">
                    Hi,{" "}
                    <span className="text-bolder text-capitalize">
                      {userData?.name}
                    </span>
                  </span>
                  {userData?.profile && !errorImage ? (
                    <Avatar
                      src={`${process.env.REACT_APP_IMG}/${userData?.profile}`}
                      size={40}
                      onError={(e) => setErrorImage(true)}
                    />
                  ) : (
                    <Avatar
                      size={40}
                      style={{
                        backgroundColor: "#fff4cb",
                        color: "#ffd333",
                      }}
                      className="text-capitalize"
                    >
                      <span className="fs-24">{userData?.name[0]}</span>
                    </Avatar>
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderNav;
