import { lazy } from "react";

const Cookies = require('js-cookie');
const role_id = Cookies.get('role_id');

const PublicRoutes = [
    // {
    //     path: "/signup",
    //     component: lazy(() => import("../pages/SignUp")),
    //     exact: true,
    // },
    {
        path: "/signin",
        component: lazy(() => import("../pages/SignIn")),
        exact: true,
    },
    {
        path: "/forgot-password",
        component: lazy(() => import("../pages/ForgetPassword")),
        exact: true,
    },
    {
        path: "/change-password",
        component: lazy(() => import("../pages/ChangePassword")),
        exact: true,
    },

    {
        path: "*",
        component: lazy(() => import("../pages/Page404")),
        exact: true,
    },
];

const PrivateRoutes = [
    {
        path: "/",
        component: lazy(() => import("../pages/Dashboard")),
        exact: true,
        access: true
    },
    {
        path: "/all-links",
        component: lazy(() => import("../pages/AllLinks")),
        exact: true,
        access: true
    },
    {
        path: "/profile",
        component: lazy(() => import("../pages/Profile")),
        exact: true,
        access: true
    },
    {
        path: "/employee",
        component: lazy(() => import("../pages/Employee")),
        exact: true,
        access: role_id === '1' || role_id === '3'
    },
    {
        path: "/department",
        component: lazy(() => import("../pages/Department")),
        exact: true,
        access: role_id === '1'
    },
    {
        path: "/employee/:status",
        component: lazy(() => import("../pages/EditEmployee")),
        exact: true,
        access: role_id === '1' || role_id === '3'
    },
    {
        path: "/employeeDetails",
        component: lazy(() => import("../pages/EmployeeDetail")),
        exact: true,
        access: true
    },
    {
        path: "/group",
        component: lazy(() => import("../pages/Group")),
        exact: true,
        access: true
    },
    {
        path: "/group/:id",
        component: lazy(() => import("../pages/Space")),
        exact: true,
        access: true
    },
    {
        path: "/space/:id",
        component: lazy(() => import("../pages/LinksPage")),
        exact: true,
        access: true
    },
    {
        path: "/preview",
        component: lazy(() => import("../pages/PreviewPage")),
        exact: true,
        access: true
    },
    {
        path: "/quick-links",
        component: lazy(() => import("../pages/QuickLinks")),
        exact: true,
        access: true
    },
].filter((i) => i.access === true);

export { PublicRoutes, PrivateRoutes };
