import { Col, Row, Avatar, Tooltip } from "antd";
import React, { useState } from "react";
import {
  PlusOutlined,
  AppstoreOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

const GlobalSearchGroup = ({ groupData,setIsSearchResult,setInputText }) => {
    
    const navigate=useNavigate();
    const routeHandle=()=>{
        setIsSearchResult(false)
        setInputText("")
    }
    const [errorImage,setErrorImage]=useState(false)
  return (
    <div className="  bg-white ">
      <Row className="mb-3 " gutter={[24, 24]}>
        <Col
          xs={24}
          md={24}
          className="fs-20 text-bolder mb-3"
          data-testid="product-title"
        >
          <span className="mr-2">
            <AppstoreOutlined />
          </span>
          <span className="">Group</span>
        </Col>
        {groupData?.data.map((group) => {
          return (
            <Col xs={24} md={4} lg={4}>
              <NavLink to={`group/${group?.id}`} onClick={routeHandle}>
              <div className="p-3 rounded shadow-lg bg-white ">
                <div className="picture d-flex align-items-center justify-content-center">
                  {group?.logo && !errorImage ? (
                    <Avatar
                      size={60}
                      src={`${process.env.REACT_APP_IMG}/${group?.logo}`}
                      alt="groupcard"
                      onError={()=>setErrorImage(true)}
                    />
                  ) : (
                    <Avatar
                      size={60}
                      style={{
                        backgroundColor: "#fff4cb",
                        color: "#ffd333",
                      }}
                      className="img-fluid text-capitalize"
                      alt="groupcard"
                      
                    >
                      <span className="fs-32">{group?.title[0]}</span>
                    </Avatar>
                  )}
                </div>
                <div className="text-center mt-3 text-capitalize">
                  <Tooltip
                    className="text-capitalize"
                    placement="bottom"
                    title={group?.title}
                  >
                    <span className="group-title cursor-pointer">
                      {group?.title?.length >= 15
                        ? group?.title?.slice(0, 13) + "..."
                        : group?.title}
                    </span>
                  </Tooltip>
                </div>
              </div>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default GlobalSearchGroup;
