import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userData : undefined,
}

export const userSlice = createSlice({
    name: 'loggedInUser',
    initialState,
    reducers: {
        setUserData: (state,action) => {
            state.userData = action.payload;
        }
    }
})

export const { setUserData } = userSlice.actions

export default userSlice.reducer