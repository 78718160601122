import { createSlice } from '@reduxjs/toolkit'

let innerWidth = window.innerWidth;

const initialState = {
    isCollapse : innerWidth > 480 ? false : true,
}

export const sidebarCollapseSlice = createSlice({
    name: 'sidebarCollapse',
    initialState,
    reducers: {
        toggleSidebar: (state,action) => {
            state.isCollapse = !state.isCollapse
        }
    }
})

export const { toggleSidebar } = sidebarCollapseSlice.actions

export default sidebarCollapseSlice.reducer